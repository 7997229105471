<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                    <AppInfoManual nomeTelaDoManual="profissionais-list" />
                    Listagem de profissionais
                </div>
                <div class="flex flex-column mb-4 md:flex-row md:justify-content-between md:align-items-center">
                    <div class="p-fluid mt-2 w-full md:mt-0 md:w-5">
                        <Chips v-model="filters" placeholder="Pesquisar" @add="search($event)" @remove="search($event)" />
                    </div>
                    <div class="mt-2 ml-auto md:mt-0">
                        <Button label="Novo" icon="pi pi-plus" class="p-button p-button-text mr-2" @click="$router.push('/profissionais/new')" />
                    </div>
                </div>

                <Toast />
                <AppLoadingWrapper v-if="loading" />
                <DataTable
                    v-if="!loading"
                    :row-hover="true"
                    :value="records"
                    class="p-datatable-sm"
                    breakpoint="640px"
                    dataKey="id"
                    responsiveLayout="stack"
                    table-style="width: 100%"
                >
                    <template #empty>Nenhum registro encontrado.</template>
                    <template #loading>Carregando registros. Aguarde ...</template>
                    <Column :sortable="true" field="nome" header="Nome" style="width: 40%"></Column>
                    <Column
                        :sortable="true"
                        header="Procedimentos"
                        style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 40%"
                    >
                        <template #body="{ data }">
                            <span>
                                {{ truncateProcedimentos(data.quantidadeProcedimentos) }}
                            </span>
                        </template>
                    </Column>

                    <Column header="Permite fora da distribuição" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 10%">
                        <template #body="{ data }">
                            {{ formatarRealizarAgendamentosForaDistribuicao(data.realizarAgendamentosForaDistribuicao) }}
                        </template>
                    </Column>

                    <Column bodyClass="text-right" style="width: 6%">
                        <template #body="slotProps">
                            <Button icon="pi pi-ellipsis-v" class="p-button-text p-button-secondary" @click="toggleMenu($event, slotProps.data)" />
                        </template>
                    </Column>
                </DataTable>
                <Menu ref="menu" :model="actionItems" :popup="true" />

                <Paginator
                    v-show="!loading"
                    :rows="perPage"
                    :totalRecords="total"
                    :first="(page - 1) * perPage"
                    @page="onPage"
                    template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
                    currentPageReportTemplate="Página {currentPage} de {totalPages}"
                />

                <Dialog v-model:visible="deleteRecordDialog" :style="{ width: '450px' }" header="Confirmar" :modal="true">
                    <div class="flex align-items-center justify-content-center">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                        <span v-if="record">
                            Tem certeza que deseja excluir <b> {{ record.name }} </b> ?
                        </span>
                    </div>
                    <template #footer>
                        <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="deleteRecordDialog = false" />
                        <Button label="Excluir" icon="pi pi-trash" class="p-button-outlined p-button-danger" @click="deleteRecord" />
                    </template>
                </Dialog>
            </div>
        </div>
    </div>
</template>

<script>
import AppInfoManual from '../../components/AppInfoManual.vue';
import AreaMedicaService from '../../services/AreaMedicaService';
import { getCurrentCompany } from '@/services/store';
import { Tooltip } from 'primevue/tooltip';

export default {
    directives: {
        tooltip: Tooltip
    },
    data() {
        return {
            records: [],
            page: 1,
            total: 0,
            totalPage: 0,
            loading: false,
            perPage: 10,
            recordDialog: false,
            deleteRecordDialog: false,
            filters: null,
            actionItems: [
                {
                    label: 'Alterar',
                    icon: 'pi pi-pencil',
                    command: () => this.editRecord(this.actionRecord.id)
                },
                {
                    label: 'Excluir',
                    icon: 'pi pi-trash',
                    command: () => this.confirmDeleteRecord(this.actionRecord)
                }
            ]
        };
    },
    mounted() {
        this.load();
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        }
    },
    watch: {
        tenant() {
            this.load();
            this.setCompanyService();
        }
    },
    methods: {
        truncateProcedimentos(quantidadeProcedimentos) {
            if (quantidadeProcedimentos <= 0) return 'Nenhum procedimento vinculado';
            if (quantidadeProcedimentos == 1) return `${quantidadeProcedimentos ?? 0} procedimento vinculado`;
            return `${quantidadeProcedimentos ?? 0} procedimentos vinculados`;
        },
        formatarRealizarAgendamentosForaDistribuicao(realizarAgendamentosForaDistribuicao) {
            if (realizarAgendamentosForaDistribuicao) return 'Sim';
            return 'Não';
        },
        toggleMenu(event, data) {
            this.actionRecord = data;
            this.$refs.menu.toggle(event);
        },
        setCompanyService() {
            this.$service = new AreaMedicaService('/profissional_medico');
        },
        search(event) {
            this.filter = event?.value;
            this.load();
        },
        editRecord(id) {
            this.$router.push('/profissionais/' + id);
        },
        confirmDeleteRecord(record) {
            this.record = record;
            this.deleteRecordDialog = true;
        },
        async deleteRecord() {
            if (this.record) {
                try {
                    console.log(this.$service);

                    await this.$service.remove(this.record.id);
                    this.record = {};
                    this.load();
                    this.deleteRecordDialog = false;
                    this.$toast.add({ severity: 'success', summary: 'Profissional excluído com sucesso!', life: 3000 });
                } catch (err) {
                    console.error(err);
                    this.$toast.add({ severity: 'error', summary: 'Erro ao excluir o profissional!', life: 3000 });
                }
            }
        },
        async load() {
            this.setCompanyService();
            this.loading = true;
            const { data } = await this.$service.findAll({ limit: this.perPage, page: this.page, filter: this.filter });
            console.log(data);
            this.records = data.items;
            this.total = data.meta?.totalItems;
            this.totalPage = data.meta?.totalPages;
            this.loading = false;
        },
        async onPage(event) {
            this.page = event.page + 1;
            this.load();
        }
    },
    components: { AppInfoManual }
};
</script>

<style scoped>
::v-deep .p-column-title {
    padding-right: 12px;
}
</style>
